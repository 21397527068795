.events-list-section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.event-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.event-image {
  width: 10%;
  height: 10%;
  border-radius: 10px;
}

.event-details {
  padding-left: 20px;
}

.event-title {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 10px;
}

.event-description {
  font-size: 1em;
  line-height: 1.4;
}

.events-headings h2 {
  font-size: 3.5rem;
  color: #333;
  margin: 0;
  position: relative;
  display: inline-block;
  margin-top: 20px;
}
