.menu-header {
  text-align: center;
  padding-bottom: 30px;
}
.menu-title h2 {
  font-size: 3.5rem;
  color: #333;
  margin: 0;
  position: relative;
  display: inline-block;
  margin-top: 20px;
}
.menu-title:after {
  content: "";
  display: block;
  width: 0%;
  height: 4px;
  margin-top: 10px;
  background-color: #ffa500;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  transition: width 0.3s ease-in-out;
}
.menu-title.active:after {
  width: 20%;
}
.menu-filters {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.filter-option {
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  position: relative;
  transition: all 0.3s;
  position: relative;
}

.filter-option span {
  transition: color 0.3s;
}

.filter-option.active span,
.filter-option:hover span {
  color: orange;
}

.filter-option svg {
  font-size: 2rem;
  margin-bottom: -3px;
}

.active-underline {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 4px;
  background-color: #ffa500;
  width: 100%;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.filter-option.active .active-underline {
  transform: scaleX(1);
}

.menu-subtitle {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  color: var(--primary-subheading-color);
}

@media (max-width: 768px) {
  .menu-header {
    padding-bottom: 20px;
  }

  .menu-title h2 {
    font-size: 2rem;
    margin-top: 10px;
  }

  .menu-filters {
    flex-direction: row;
    gap: 10px;
  }

  .filter-option {
    padding: 5px 15px;
    gap: 5px;
  }

  .filter-option svg {
    font-size: 1.5rem;
  }

  .active-underline {
    bottom: -5px;
    height: 2px;
    width: 100%;
  }
}
