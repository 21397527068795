@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600&family=Nunito:wght@600;700;800&family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Comfortaa" "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Comfortaa",
    "Courier New", monospace;
}
@font-face {
  font-family: "nautilus_pompiliusregular";
  src: url("./assets/fonts/nautilus-webfont.woff2") format("woff2"),
    url("./assets/fonts/nautilus-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
