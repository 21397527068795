.about-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: var(--primary-background-color);
  padding: 0 15% 10% 15%;
}

.about-content {
  margin-top: 4rem;
  max-width: 50%;
}

.about-title {
  font-size: 3rem;
  color: var(--primary-heading-color);
  margin-bottom: 1rem;
  font-weight: bold;
}

.about-subtitle {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  color: var(--primary-subheading-color);
}
.about-subtitle:after {
  content: "";
  display: block;
  width: 0;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: var(--primary-heading-color);
  transition: width 0.3s ease-in-out;
}

.about-subtitle.active:after {
  width: 250px;
}

.about-images {
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-main {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    align-items: center;
  }
  .about-subtitle:after {
    width: 150px;
    height: 3px;
  }
  .about-subtitle.active:after {
    margin: 10px auto 0;
    width: 50%;
  }

  .about-content,
  .about-images {
    max-width: 100%;
  }
  .favorites-container {
    flex-direction: column;
    gap: 20px;
  }

  .favorite-card {
    margin: 10px auto;
  }
}
