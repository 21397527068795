.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 1.8%;
  transition: background-color 0.6s ease;
}
.navbar-solid {
  background-color: rgba(0, 0, 0, 0.9);
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.9);
}
.nav-link {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  overflow: hidden;
}

.nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ffa500;
  transition: width 0.3s ease;
}

.nav-link:hover::before,
.nav-link.active::before {
  width: 100%;
}
.nav-link:hover::after,
.nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ffa500;
  transition: width 0.3s ease;
}

.nav-link.active {
  color: #ffa500;
}

.resized-image {
  width: 27%;
}

@media (max-width: 780px) {
  .navbar {
    position: relative;
    background-color: rgba(0, 0, 0, 6);
    transition: padding-top 0.3s ease;
  }

  .resized-image {
    width: 30%;
  }

  .mobile-nav {
    position: absolute;
    background-color: rgba(0, 0, 0, 1);
    padding: 0 0 0 4%;
    left: -0.5rem;
    width: 104%;
  }
}
